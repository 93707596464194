import { Button } from '@material-ui/core';
import { groupBy, sumBy } from 'lodash';
import React, { useMemo } from 'react';

import Table, { StatBox, TableText } from '../../Table';
import { Campaign, CampaignInfluencer } from '../../types';
import { campaignHasStreamlitStats, findInfluencerByName } from '../../utils';
import { InfluencersToolTip } from '../InfluencersToolTip';
import { TopMoment } from '../types';
import {
  getValidTopMoments,
  getVideoByMoment,
  getVideoIdByMoment,
} from '../utils';

const ROW_HEIGHT = 140;
type Filters = {
  selectedCategory: string;
  selectedInfluencer: string;
  languageFilter: string[];
  selectedRegion: string;
  selectedPlatform: string;
};

const getCols = (data: TopMoment[], setActiveTab, setStatsProps, hasStats) => {
  const hasCreatorSentimentColumn = () => {
    return data.some((row) => !!row.creator_sentiment);
  };

  const hasAudienceSentimentColumn = () => {
    return data.some((row) => !!row.audience_sentiment);
  };

  return [
    {
      label: 'Topic Name',
      key: 'topic',
      render: (row: Row) => {
        return <TableText fontSize="1.5em">{row.name}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Category',
      key: 'category',
      render: (row: Row) => {
        return <TableText fontSize="1.5em">{row.category}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Creators',
      key: 'topicCreators',
      render: (row: Row) => {
        return (
          <InfluencersToolTip
            influencers={row.topicCreators}
            content={<StatBox stat={row.topicCreators.length} />}
          />
        );
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Videos',
      key: 'topicVideos',
      render: (row: Row) => {
        return <StatBox stat={row.topicVideos.length} />;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Comments',
      key: 'topicCommentsSum',
      render: (row: Row) => {
        return <StatBox stat={row.topicCommentsSum} />;
      },
      stretch: true,
      flex: 0.5,
    },
    hasCreatorSentimentColumn && {
      label: 'Creator Positive Sentiment',
      key: 'creatorPositiveSentimentRatio',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.creatorPositiveSentimentRatio * 100}
            suffix="%"
            info={`${row.creatorPositiveSentimentMoments.length} positive moments out of ${row.topicTopMoments.length} top moments`}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    hasAudienceSentimentColumn && {
      label: 'Audience Positive Sentiment',
      key: 'audiencePositiveSentimentRatio',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.audiencePositiveSentimentRatio * 100}
            suffix="%"
            info={`${row.audiencePositiveSentimentMoments.length} positive comments out of ${row.topicCommentsSum} comments in ${row.topicTopMoments.length} top moments`}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Engaged Audience',
      key: 'engagedAudienceRatio',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.engagedAudienceRatio * 100}
            suffix="%"
            info={`${row.topicCommentsSum} topic related comments out of ${row.topMomentsCommentsSum} game-related comments`}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    hasStats && {
      label: 'Feedback',
      key: 'feedback',
      render: (row: Row) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                borderWidth: 1,
              }}
              onClick={() => {
                setActiveTab('stats');
                setStatsProps({
                  topic: row.name,
                  feedback: 'suggestion',
                });
              }}
            >
              Recommendations
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                borderWidth: 1,
              }}
              onClick={() => {
                setActiveTab('stats');
                setStatsProps({
                  topic: row.name,
                  feedback: 'criticism',
                });
              }}
            >
              Criticism
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                borderWidth: 1,
              }}
              onClick={() => {
                setActiveTab('stats');
                setStatsProps({
                  topic: row.name,
                  feedback: 'praise',
                });
              }}
            >
              Praise
            </Button>
          </div>
        );
      },
      stretch: true,
      flex: 0.75,
    },
  ].filter(Boolean);
};

const filterData = ({
  data,
  filters,
  campaign,
}: {
  data: TopMoment[];
  filters: Filters;
  campaign: Campaign;
}) => {
  return data.filter((moment) => {
    const video = getVideoByMoment(moment, campaign.videos) || {};

    return (
      (filters.selectedInfluencer === 'all' ||
        moment.streamer.toLowerCase() ===
          filters.selectedInfluencer.toLowerCase()) &&
      (filters.selectedCategory === 'all' ||
        moment.category.toLowerCase() ===
          filters.selectedCategory.toLowerCase()) &&
      (filters.languageFilter.length === 0 ||
        filters.languageFilter.includes(video.language)) &&
      (filters.selectedRegion === 'all' ||
        moment.region.toLowerCase() === filters.selectedRegion.toLowerCase()) &&
      (filters.selectedPlatform === 'all' ||
        moment.platform.toLowerCase() ===
          filters.selectedPlatform.toLowerCase())
    );
  });
};

const computeTopicStats = ({
  topicData,
  campaign,
  originalData,
}: {
  topicData: TopMoment[];
  campaign: Campaign;
  originalData: TopMoment[];
}) => {
  const category = topicData[0].category;
  const topic = topicData[0].topic;
  const topicTopMoments = getValidTopMoments(topicData);
  const originalTopMoments = getValidTopMoments(originalData);

  const uniqueTopicCreators = new Set(
    topicTopMoments.map((row) => row.streamer)
  );
  const topicCreators = Array.from(uniqueTopicCreators)
    .map((creatorName) => {
      const creator = findInfluencerByName(
        creatorName,
        campaign.campaign_influencers
      ) as CampaignInfluencer | undefined;
      return creator;
    })
    .filter((creator) => !!creator) as CampaignInfluencer[];
  const campaignCreators = campaign.campaign_influencers;

  const topicVideosIds = new Set(
    topicData.map((row) => getVideoIdByMoment(row))
  );
  const topicVideos = campaign.videos.filter((video) =>
    topicVideosIds.has(video.external_id)
  );

  const campaignVideosIds = Array.from(
    new Set(campaign.videos.map((video) => video.id))
  );

  const topMomentsCommentsSum = sumBy(
    originalTopMoments,
    (row) => row.moment_length
  );
  const topicCommentsSum = sumBy(topicTopMoments, (row) => row.moment_length);

  // Creator positive sentiment ratio
  const creatorPositiveSentimentMoments = topicTopMoments.filter(
    (row) => row.creator_sentiment === 'positive'
  );
  const creatorPositiveSentimentRatio =
    creatorPositiveSentimentMoments.length / topicTopMoments.length;

  // Audience positive sentiment ratio
  const audiencePositiveSentimentMoments = topicData.filter(
    (row) => row.audience_sentiment === 'positive'
  );
  const audiencePositiveSentimentRatio =
    audiencePositiveSentimentMoments.length / topicData.length;

  const engagedAudienceRatio = topicCommentsSum / topMomentsCommentsSum;

  return {
    name: topic,
    category,
    topicCreators,
    topicTopMoments,
    campaignCreators,
    topicVideos,
    campaignVideosIds,
    topicCommentsSum,
    topMomentsCommentsSum,
    creatorPositiveSentimentMoments,
    creatorPositiveSentimentRatio,
    audiencePositiveSentimentMoments,
    audiencePositiveSentimentRatio,
    engagedAudienceRatio,
    key: topic,
  };
};

const getRows = ({
  data,
  campaign,
  filters,
}: {
  data: TopMoment[];
  campaign: Campaign;
  filters: Filters;
}) => {
  const filteredData = filterData({ data, filters, campaign });
  const byTopicFiltered = groupBy(filteredData, 'topic');

  return Object.values(byTopicFiltered)
    .map((topicData) => {
      return computeTopicStats({
        topicData,
        campaign,
        originalData: data,
      });
    })
    .filter((topic) => topic.topicTopMoments.length > 0);
};

type Row = ReturnType<typeof getRows>[0];

export const TopTopicsTable = ({
  data,
  campaign,
  filters,
  setActiveTab,
  setStatsProps,
}: {
  data: TopMoment[];
  campaign: Campaign;
  filters: Filters;
  setActiveTab: (tab: string) => void;
  setStatsProps: (props: any) => void;
}) => {
  const rows = useMemo(
    () =>
      getRows({
        data,
        campaign,
        filters,
      }),
    [data, campaign, filters]
  );

  const hasStats = campaignHasStreamlitStats(campaign);

  const cols = useMemo(
    () => getCols(data, setActiveTab, setStatsProps, hasStats),
    [data, setActiveTab, setStatsProps, hasStats]
  );

  return (
    <>
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="topicCommentsSum"
        idTable={'top_topics_table'}
        sortKey={undefined}
        noBorders={undefined}
        defaultIncreasing={undefined}
        onRowClick={undefined}
        onCreatorColClick={undefined}
      />
    </>
  );
};
